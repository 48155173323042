@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&family=Raleway:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Prompt:wght@300;400;500&display=swap");
@import "material-icons/iconfont/material-icons.css";
@import "./css/animations.scss";

:root {
  --main: #fefdf9;
}
html {
  font-family: "Prompt", sans-serif;
  font-size: 16px;
  height: 100vh;
  overflow: hidden auto;
  width: 100vw;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.subheading {
  font-family: "Prompt", sans-serif;
  margin: 0;
}

body {
  background-color: var(--main);
  font-size: 90%;
  margin: 0;
  overflow: hidden visible;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  position: relative;
  width: 100%;
}

body * {
  box-sizing: border-box;

  position: inherit;
  z-index: 1;
}

footer {
  font-weight: 200;
  font-size: 1.64rem;
  padding: 2vmax 0;
}

.image {
  animation-fill-mode: forwards;
  animation: fade-in ease-out 250ms, scale-in ease-out 500ms;
  height: 100%;
  max-height: 348px;
  max-width: 348px;
  padding: 0.5rem;
  width: 100%;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes scale-in {
  from {
    transform: scale(1.1);
  }
  to {
    transform: scale(1);
  }
}

@media screen and (max-width: 600px) {
  #logo {
    width: 60vmin;
  }
}
